<template>
  <v-dialog v-model="show" width="400px">
    <v-card :style="[{ borderTop: `4px solid ${mainColor}` }]" class="pa-5">
      <v-card-title class="pa-0 pb-2">
        <div class="d-flex justify-space-between align-center" style="width: 100%;">
          <h5 class="main--text">{{ translations.updateNotesFor }}:</h5>

          <v-btn @click="$emit('close'), (edit = false)" fab width="18px" height="18px" color="main" depressed
            ><v-icon small color="white">{{ icons.mdiClose }}</v-icon></v-btn
          >
        </div>

        <span style="font-size: .875rem">{{ timeline.name }}</span>
      </v-card-title>

      <div class="d-flex align-center justify-space-between">
        <div class="d-inline-block">
          <p class="mb-0" style="font-weight: bold">{{ translations.dateFrom }}</p>

          <p>{{ momentDate(timeline.start, "DD/MM/YYYY") }}</p>
        </div>

        <div class="d-inline-block">
          <p class="mb-0" style="font-weight: bold">{{ translations.toDate }}</p>

          <p>{{ momentDate(timeline.end, "DD/MM/YYYY") }}</p>
        </div>
      </div>
      <v-divider :style="[{ color: mainColor }, { height: '4px' }]"></v-divider>

      <v-card-text class="pa-0 mt-2">
        <v-textarea v-if="edit" v-model="note" solo></v-textarea>
        <p v-else>{{ note }}</p>
      </v-card-text>

      <div class="d-flex justify-end mt-2">
        <v-btn v-if="edit" @click="updateNoteHandler" class="white--text" color="main" small>{{ translations.updateNotes }}</v-btn>
        <v-btn v-else @click="edit = true" class="white--text" color="main" small>{{ translations.edit }}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";
import { translations } from "@/utils/common";
import { MomentMixin } from "@/utils/mixins/MomentMixin.js";

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },

    timeline: {
      type: Object,
      required: true,
      default: () => {}
    },

    loading: {
      type: Boolean,
      required: true
    }
  },

  mixins: [MomentMixin],

  data: () => ({
    note: "",
    edit: false
  }),

  created() {
    if (this.timeline?.title) this.note = this.timeline.title;
  },

  methods: {
    updateNoteHandler() {
      const payload = {
        note: this.note,
        timelineId: this.timeline.timelineId
      };

      this.$emit("update-note", payload);
    }
  },

  computed: {
    translations: () => translations,

    mainColor() {
      return this.$vuetify.theme.themes.light.main;
    },

    icons: () => ({ mdiClose })
  }
};
</script>
